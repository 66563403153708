
  import {createControllersWithDescriptors, initAppForPageWrapper} from '@wix/yoshi-flow-editor/runtime/esm/viewerScript.js';
  
  
            
const wrapController0 = null;

            import controller0 from '/home/builduser/work/fa85d65a9160597f/packages/members-viewer-app/src/components/members/controller.ts';
            import * as _controllerExport0 from '/home/builduser/work/fa85d65a9160597f/packages/members-viewer-app/src/components/members/controller.ts';
            var controllerExport0 = _controllerExport0;
            

            
const wrapController1 = null;

            import controller1 from '/home/builduser/work/fa85d65a9160597f/packages/members-viewer-app/src/components/members-login-bar/controller.ts';
            import * as _controllerExport1 from '/home/builduser/work/fa85d65a9160597f/packages/members-viewer-app/src/components/members-login-bar/controller.ts';
            var controllerExport1 = _controllerExport1;
            

            
const wrapController2 = null;

            import controller2 from '/home/builduser/work/fa85d65a9160597f/packages/members-viewer-app/src/components/members-menu/controller.ts';
            import * as _controllerExport2 from '/home/builduser/work/fa85d65a9160597f/packages/members-viewer-app/src/components/members-menu/controller.ts';
            var controllerExport2 = _controllerExport2;
            

  
  import * as viewerApp from '/home/builduser/work/fa85d65a9160597f/packages/members-viewer-app/src/viewer.app.ts';
    var importedApp = viewerApp;


  

    var velocycleMobx = null;
    


  

    var blocksControllerService = null;
    

  
var createHttpClient = null;

  
var initI18n = null;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = null;

  var experimentsConfig = {"scopes":[],"centralized":true};

  var translationsConfig = {"enabled":false,"icuEnabled":false};

  var defaultTranslations = null;

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/fa85d65a9160597f/packages/members-viewer-app/target/generated/bi/createBILogger.ts';

  export const exports = importedApp.exports;

  export const initAppForPage = initAppForPageWrapper({
    initAppForPage: importedApp.initAppForPage,
    sentryConfig: sentryConfig,
    experimentsConfig: experimentsConfig,
    inEditor: false,
    biLogger: biLogger,
    projectName: "santa-members-viewer-app",
    biConfig: null,
    appName: "Members Area Viewer Script",
    appDefinitionId: "14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9",
    fedopsConfig: fedopsConfig,
    translationsConfig: translationsConfig,
    defaultTranslations: defaultTranslations,
    shouldUseEssentials: true,
    optionalDeps: {
      initI18n,
      createHttpClient,
      createExperiments,
    },
    localeDistPath: "assets/locales",
  });

  const _createControllers = createControllersWithDescriptors({
    initI18n,
    blocksControllerService,
    createHttpClient,
    createExperiments,
    velocycleMobx,
  }, [{ method: controller0,
          wrap: wrapController0,
          exports: controllerExport0,
          widgetType: "PLATFORM_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"scopes":[],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/fa85d65a9160597f/packages/members-viewer-app/src/components/members/controller.ts",
          appName: "Members Area Viewer Script",
          appDefinitionId: "14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9",
          projectName: "santa-members-viewer-app",
          componentName: "members",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "members" }, { method: controller1,
          wrap: wrapController1,
          exports: controllerExport1,
          widgetType: "PLATFORM_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"scopes":[],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/fa85d65a9160597f/packages/members-viewer-app/src/components/members-login-bar/controller.ts",
          appName: "Members Area Viewer Script",
          appDefinitionId: "14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9",
          projectName: "santa-members-viewer-app",
          componentName: "members-login-bar",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "members-login-bar" }, { method: controller2,
          wrap: wrapController2,
          exports: controllerExport2,
          widgetType: "PLATFORM_WIDGET",
          translationsConfig: translationsConfig,
          experimentsConfig: {"scopes":[],"centralized":true},
          fedopsConfig: fedopsConfig,
          sentryConfig: sentryConfig,
          persistentAcrossPages: false,
          biLogger: biLogger,
          shouldUseEssentials: true,
          withErrorBoundary: false,
          biConfig: null,
          controllerFileName: "/home/builduser/work/fa85d65a9160597f/packages/members-viewer-app/src/components/members-menu/controller.ts",
          appName: "Members Area Viewer Script",
          appDefinitionId: "14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9",
          projectName: "santa-members-viewer-app",
          componentName: "members-menu",
          localeDistPath: "assets/locales",
          defaultTranslations: defaultTranslations,
          id: "members-menu" }],
    true);

    export const createControllers = _createControllers
